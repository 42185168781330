.bar{
  position: sticky;
  bottom:0;
  height:100px;
  margin-left:-16px;
  background-color: @medium-dark;
  display:flex;
  flex-direction: row;
  justify-content: flex-end;
  grid-column-gap: 20px;
  width: calc(100% + 32px);
  align-items:center;
  padding-right: 24px;
  padding-left: 24px;

  button span{
    text-transform: uppercase!important;
  }
}

@darkest: #171821;@dark: #1A1B25;@medium-dark: #21222E;@light-dark: #282937;@medium: #53545F;@dark-light: #7E7F87;@light: #A9A9AF;@light-light: #D4D4D7;@lightest-light: #FFFFFF;@red: #FB6363;@input-bg: #A9A9AE;@primary-color: #EAEA4E;@font-family: 'Inter', sans-serif;@layout-header-background: #1A1B25;@main-background: #171821;@layout-body-background: #171821;@button-color: #000000;@text-color: #FFFFFF;@secondary-bg: #171821;@secondary-bg-lighter: #282937;@light-bg: #7E7F87;
.video{
  width: 360px;
  height: 200px;
  object-fit:cover;
  @media(max-width:768px){
    width:100%;
    height:auto;
  }
}
.innerContainer{
  .row{
    display:flex;
    flex-direction: row;
    flex-wrap:wrap;
    grid-gap:30px;
    margin-bottom:30px
  }

  @media(max-width:1080px){
    .row{
      flex-direction: column;
      .topCard{
        width:100% !important;
      }
    }
  }



}

.small{
  font-size:1rem;
  font-weight:400;
  color:white;
}
.topCard{
  height:300px;
  width: 30%;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  text-align:left;
  background-color: @dark;
  border-radius:8px;
  padding:24px;

  .big{
    font-size:3.8rem;
    margin-bottom:0
  }

  @media(max-width:768px){
      height:153px;
      .big{
        text-align: right;
        font-size:4.5rem;
        margin-top:-20px
      }
  }
}

.bottomCard{
  height:157px;
  width: 30%;

  display:flex;
  flex-direction: column;
  text-align:center;
  background-color: @dark;
  justify-content: center;
  border-radius:8px;
  padding:24px;
}

.title{
  margin-top:64px;
  margin-bottom:48px;
  font-size:2.5rem;
  @media(max-width:768px){
    font-size: 1.5rem!important;
  }
}
.addRow{
  flex-direction: row;
  cursor: pointer;
  color: @light;
  text-align: left;
  font-size: 18px;
  margin-top: auto;
  margin-left:auto
}

@darkest: #171821;@dark: #1A1B25;@medium-dark: #21222E;@light-dark: #282937;@medium: #53545F;@dark-light: #7E7F87;@light: #A9A9AF;@light-light: #D4D4D7;@lightest-light: #FFFFFF;@red: #FB6363;@input-bg: #A9A9AE;@primary-color: #EAEA4E;@font-family: 'Inter', sans-serif;@layout-header-background: #1A1B25;@main-background: #171821;@layout-body-background: #171821;@button-color: #000000;@text-color: #FFFFFF;@secondary-bg: #171821;@secondary-bg-lighter: #282937;@light-bg: #7E7F87;
.center{
  text-align: center;
  margin-top:20px;
  max-width: 500px;
  margin:auto;
  margin-bottom: 20px;
  p{
    font-size: 1.3rem;
    margin-top:15px
  }
  .body{
    font-size: 1.3rem;
    font-weight: 400;

  }
}

.footer{
  background-color: @medium-dark;
  margin-top:2rem;
  justify-content: flex-end;
  padding:1rem;
  bottom:0;
  width:100%
}

.formContainer{
  overflow: auto;
  max-height: 600px;

  :global(.ant-modal-footer){
    display:none
  }
}

.risposte{
  display: flex;
  flex-direction: column;
  min-height: 300px;
}

.risposteWrapper{
  display: flex;
  flex-direction: column;
  text-align: left;
  align-items: flex-start;
  width: 60%;
  grid-row-gap:40px;
  margin:auto;
  text-align: left;
  margin-top:40px;

  @media(max-width:768px){
    width: 100%

  }

}

@darkest: #171821;@dark: #1A1B25;@medium-dark: #21222E;@light-dark: #282937;@medium: #53545F;@dark-light: #7E7F87;@light: #A9A9AF;@light-light: #D4D4D7;@lightest-light: #FFFFFF;@red: #FB6363;@input-bg: #A9A9AE;@primary-color: #EAEA4E;@font-family: 'Inter', sans-serif;@layout-header-background: #1A1B25;@main-background: #171821;@layout-body-background: #171821;@button-color: #000000;@text-color: #FFFFFF;@secondary-bg: #171821;@secondary-bg-lighter: #282937;@light-bg: #7E7F87;
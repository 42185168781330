.container{
  margin-left:auto;
  margin-right: auto;

  h1{
    font-size:2.2rem;
    margin-bottom:3rem;
  }
}



.anchors{
  flex:1;
}

.content{
  flex:5;
  text-align: right;
  overflow: auto;
  margin-left: 20%;

}

.lezioniWrapper{
  width:100%;

  background-color: @dark;

  margin-bottom:632px;
  &.empty{
    justify-content: center;
    display: flex;
    align-content: center;
    flex-direction: column;
    align-items: center;
    min-height: 500px;
  }
}

@darkest: #171821;@dark: #1A1B25;@medium-dark: #21222E;@light-dark: #282937;@medium: #53545F;@dark-light: #7E7F87;@light: #A9A9AF;@light-light: #D4D4D7;@lightest-light: #FFFFFF;@red: #FB6363;@input-bg: #A9A9AE;@primary-color: #EAEA4E;@font-family: 'Inter', sans-serif;@layout-header-background: #1A1B25;@main-background: #171821;@layout-body-background: #171821;@button-color: #000000;@text-color: #FFFFFF;@secondary-bg: #171821;@secondary-bg-lighter: #282937;@light-bg: #7E7F87;
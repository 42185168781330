@import '~antd/dist/antd.dark.less';

.App {
  text-align: center;
  font-size: 20px;




}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.row{
  display:flex;
  flex-direction: row;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1,h2,h3,h4,h5,h6, p{
  color: @text-color
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border-right: none;
}

.ant-btn-primary{
  color: @darkest;
  font-weight: 500;
}
.ant-btn.solid{
  border:none;
  background-color: @medium-dark;
}
.ant-btn.ctaSolid{
  border:none;
  background-color: @medium-dark;
  color: @primary-color
}

.ant-btn.delete{
  border:none;
  background-color: @medium-dark;
  color: @red
}

.ant-table-thead{
  background:@main-background;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input{
  background-color: @light-light;
}

.ant-input[disabled]{
  color:@darkest;
  background-color: @light-light;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  background-color: @light;
  color: @text-color;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: text;
  height: 44px;
  /* margin-top: -3px; */
  top: -3px;
}

.ant-select{
  min-width: 200px;
}

.ant-page-header-heading{
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color:@medium-dark;
    color:white;
    border:none;
    margin-top: -3.5px;
  }
.ant-select-selection-item{
  color:white!important;
}

}

.ant-page-header-heading-title{
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media(max-width:768px){
    font-size: 1.5rem!important;
  }
}
.ant-input{
 background-color: @light;
  color: @darkest;
  padding: 11px 11px;
  border:none;
height:60px;
  font-size:16px
}

input::placeholder, textarea::placeholder,.ant-select-selection-placeholder{
  color: @medium!important;
  font-weight: 400;
}


.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  background-color: #707070;
  border-color: #a61d24 !important;
}
.ant-select-arrow{
  top:46%
}
.ant-btn-lg {
  height: 60px;
  width: 207px;
  padding: 6.4px 15px;
  border-radius: 14px;
  font-size: 16px;
  border-radius: 9px;
  font-weight: 600;
}
.ant-btn-default{
  border:2px solid white;
}

.ant-menu{
  background-color:@layout-header-background ;
}

.ant-page-header-heading-title{
  font-weight: 400;
  font-size:2.5rem;

}

.ant-input-search-button{
  border:none;
}

.ant-input-group-addon{

}

.ant-page-header-back {
  /* margin-right: 16px; */
  top: -22px;
  position: absolute;
  font-size: 24px;
  line-height: 1;
  @media(max-width:768px){
    position: relative;
    top:0;
    span{
      display: none;
    }
  }
}

.ant-page-header{
  padding-left:0;
  margin-bottom:94px;
  margin-top: 20px;
  @media(max-width:768px) {
    margin-bottom:24px;

  }
  }

.formRow{
  display:flex;
  grid-column-gap: 20px;
  div{
    cursor:pointer
  }

  &.sticky{
    position: sticky;
    top:10px;
    background: @darkest;
    z-index: 999;
    padding-top:1rem;
    padding-bottom:1rem
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color:transparent;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:hover {
  background-color: #282916;
}

.ant-menu-item{
  border-left: none!important;
}


.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
border-right:none!important;
}

.ant-menu-light .ant-menu-item:hover{
  color: white
}

.ant-menu-light .ant-menu-item-selected:hover{
  color: @primary-color!important;
}

.ant-select-selection__placeholder{
  color : blue;
}


// tables

.ant-table-thead {
  background: transparent
}

.ant-table-thead > tr > th{
  background: transparent;
  border:none;
}

.ant-table{
  background: transparent;

}

.ant-table-tbody > tr > td {
  border-bottom: 16px solid @darkest;
  background-color: @dark;
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
display:none
}


.ant-anchor-ink::before {
  display:none
}

.ant-anchor-ink-ball{
  display: none!important;
}

.ant-anchor-link-active > .ant-anchor-link-title {
  color: white;
}

.ant-anchor-link-title {
  font-size:1.4rem;
  font-weight: 300;
  color:@medium;
  &:hover{
    color:white!important;
  }
}

.ant-upload{
  width:100%
}


.ant-modal-title{
  font-size:2rem;

  @media(max-width: 768px){
    font-size:1.5rem;
  }
}

.ant-modal-content, .ant-modal-header{
  border:none;
  max-width: 1000px;
  background-color:@darkest ;
}
.ant-modal-header{
  padding:16px 24px;
  padding-top:28px
}

.ant-modal-footer{
  background-color:@medium-dark ;
}

.ant-modal-centered.right{

}



.ant-modal-mask {

  background-color: rgba(0, 0, 0, 0.45);
}

.ant-select-selector {
  background-color: #A9A9AF;
  color: @darkest!important;
  align-items: center;
  height: 60px!important;
  font-size: 16px!important;
}

.ant-picker, .rmdp-input{
  background-color: #A9A9AF;
  align-items: center;
  color: @darkest!important;

  height: 60px!important;
  font-size: 16px!important;
}
.ant-picker-input input{
  color: @darkest!important;
  font-size: 16px!important;

}
.ant-picker-suffix{
  color: @darkest!important;
}

.ant-select-arrow{
  color: @darkest;

}

.icons{
  font-size:1.3rem;

}


.ant-tabs-tab .ant-tabs-tab-btn {
  color: white;
  font-weight: 300;
  font-size:1.2rem
}

.ant-tabs-left > .ant-tabs-content-holder, .ant-tabs-left > div > .ant-tabs-content-holder {
border-left: none;
  margin-left:2px
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar {
  display:none

}

.ant-select-multiple .ant-select-selection-item-remove{
  color:@darkest
}

.ant-select-clear{
  background-color: transparent;
  color:@darkest

}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height:60px

}

.ant-input-search .ant-input:focus, .ant-input-focused {
  border:none;
  box-shadow:none;
  border-right-width:0;
  outline: 0;
}

.ant-input-group-addon{
  background: @medium-dark;
}

.ant-input-search input{
  background: @medium-dark;
  color:white
}

.ant-tooltip-inner {
  background-color: @medium-dark;
}

.ant-tooltip-arrow-content {
  --antd-arrow-background-color: linear-gradient(to right bottom,@medium, @medium-dark);
}

.ant-upload.ant-upload-select-picture-card {

  background-color: @input-bg;

}

.details{
  text-transform: uppercase;
  color:@light;
  font-size:0.9rem
}

.ant-select-dropdown{
  background: @dark
}

.tab.active{
  background-color: @medium;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}

.innerContent{
  height:140vh;
  @media(max-width:768px){
    height:200vh
  }
}

.innerContentSm{
  height:140vh
}

.ant-notification-notice{
  background-color: @medium-dark;
}

.ant-layout-sider.utente{
  @media(max-width:768px){
    display: none;
  }
}

.site-layout{
  margin-left:200px;

  &.utente{
    @media(max-width:768px){
      margin-left: 0;
    }
  }
}


.ant-checkbox-checked .ant-checkbox-inner {
  border-color: white!important;
  background-color: transparent;
}

@darkest: #171821;@dark: #1A1B25;@medium-dark: #21222E;@light-dark: #282937;@medium: #53545F;@dark-light: #7E7F87;@light: #A9A9AF;@light-light: #D4D4D7;@lightest-light: #FFFFFF;@red: #FB6363;@input-bg: #A9A9AE;@primary-color: #EAEA4E;@font-family: 'Inter', sans-serif;@layout-header-background: #1A1B25;@main-background: #171821;@layout-body-background: #171821;@button-color: #000000;@text-color: #FFFFFF;@secondary-bg: #171821;@secondary-bg-lighter: #282937;@light-bg: #7E7F87;

.form-container{
  padding:20px
}

.corsoCard{
  padding-right:1rem;
  padding-left:1rem;
  justify-content: space-between;
  background-color: @input-bg;
  border-radius: 1px;
  align-items: center;
  flex:1;
  height: 58px;
  margin-top:4px;
  margin-bottom: 15px;
  p{
    color:@darkest;

    margin:0;
    font-weight: 400;
  }
}

.icon{
  color:@darkest;

  cursor:pointer
}

@darkest: #171821;@dark: #1A1B25;@medium-dark: #21222E;@light-dark: #282937;@medium: #53545F;@dark-light: #7E7F87;@light: #A9A9AF;@light-light: #D4D4D7;@lightest-light: #FFFFFF;@red: #FB6363;@input-bg: #A9A9AE;@primary-color: #EAEA4E;@font-family: 'Inter', sans-serif;@layout-header-background: #1A1B25;@main-background: #171821;@layout-body-background: #171821;@button-color: #000000;@text-color: #FFFFFF;@secondary-bg: #171821;@secondary-bg-lighter: #282937;@light-bg: #7E7F87;
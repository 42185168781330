.bar{
  position: sticky;
  bottom:0;
  height:60px;
  display:flex;
  background-color: red;
  display:none;
  flex-direction: row;
  justify-content: space-around;
  background-color: @dark;
  box-shadow: 0px -8px 8px 1px rgb(0 0 0 / 20%);
  @media (max-width:768px){
    display:flex
  }

}

.tab{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-content: center;
  align-items: center;
  grid-gap:4px;
  font-size: 12px;
  &.tabSelected{
    color: @primary-color!important;
  }
}

@darkest: #171821;@dark: #1A1B25;@medium-dark: #21222E;@light-dark: #282937;@medium: #53545F;@dark-light: #7E7F87;@light: #A9A9AF;@light-light: #D4D4D7;@lightest-light: #FFFFFF;@red: #FB6363;@input-bg: #A9A9AE;@primary-color: #EAEA4E;@font-family: 'Inter', sans-serif;@layout-header-background: #1A1B25;@main-background: #171821;@layout-body-background: #171821;@button-color: #000000;@text-color: #FFFFFF;@secondary-bg: #171821;@secondary-bg-lighter: #282937;@light-bg: #7E7F87;
.loginContainer{
  background-color: @main-background;
  align-content: center;
  h1{
    font-size: 42px;
    color:white;
    font-weight: bold;
    margin-bottom: 0;
  };
  p {
    color:white;
    margin-top:0;
    font-size: 18px;

  }
  :global(.ant-input){
    background-color: transparent;
  }
}

.formLayout{
  background-color: @main-background;
}

.loginWrapper{
  max-width:550px;
  margin:auto;
}

.image{
  opacity: 1;
  background-position: bottom;
  display:flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;

}


@darkest: #171821;@dark: #1A1B25;@medium-dark: #21222E;@light-dark: #282937;@medium: #53545F;@dark-light: #7E7F87;@light: #A9A9AF;@light-light: #D4D4D7;@lightest-light: #FFFFFF;@red: #FB6363;@input-bg: #A9A9AE;@primary-color: #EAEA4E;@font-family: 'Inter', sans-serif;@layout-header-background: #1A1B25;@main-background: #171821;@layout-body-background: #171821;@button-color: #000000;@text-color: #FFFFFF;@secondary-bg: #171821;@secondary-bg-lighter: #282937;@light-bg: #7E7F87;
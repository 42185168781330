.upload {
  :global(.ant-upload),
  :global(.ant-upload-list-picture-card-container) {
    width: 460px;
    height: 375px;
  }
}

.wrapper{
  background-color: @input-bg;
  border-radius: 8px;
  padding:2rem;
  display: flex;
  justify-content: center;
}

.inner{
  display: flex;
  flex:1;
  flex-direction: column;
  border-radius: 8px;
  padding:1rem;
  justify-content: center;
}

.text{
  color: @darkest;
  font-width: 300;
  text-align: center;
}

@darkest: #171821;@dark: #1A1B25;@medium-dark: #21222E;@light-dark: #282937;@medium: #53545F;@dark-light: #7E7F87;@light: #A9A9AF;@light-light: #D4D4D7;@lightest-light: #FFFFFF;@red: #FB6363;@input-bg: #A9A9AE;@primary-color: #EAEA4E;@font-family: 'Inter', sans-serif;@layout-header-background: #1A1B25;@main-background: #171821;@layout-body-background: #171821;@button-color: #000000;@text-color: #FFFFFF;@secondary-bg: #171821;@secondary-bg-lighter: #282937;@light-bg: #7E7F87;
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
}

.ant-select {
    padding-top:3.5px;
    padding-bottom:3.5px;
}

.ant-layout {

}

.ant-input-disabled{
    background: white!important;
}
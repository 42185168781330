.container{
  margin:auto;
  margin-top:59px;
  label{
    color:white;
    font-weight: bold;
  };
  span[role=img]{
    color:@primary-color!important;
  }
  input{
    width:100%;
    color:white!important;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active
  {
    color:white!important;
    -webkit-box-shadow: 0 0 0 30px @main-background inset !important;
    -webkit-text-fill-color: white !important;
  }
}
@darkest: #171821;@dark: #1A1B25;@medium-dark: #21222E;@light-dark: #282937;@medium: #53545F;@dark-light: #7E7F87;@light: #A9A9AF;@light-light: #D4D4D7;@lightest-light: #FFFFFF;@red: #FB6363;@input-bg: #A9A9AE;@primary-color: #EAEA4E;@font-family: 'Inter', sans-serif;@layout-header-background: #1A1B25;@main-background: #171821;@layout-body-background: #171821;@button-color: #000000;@text-color: #FFFFFF;@secondary-bg: #171821;@secondary-bg-lighter: #282937;@light-bg: #7E7F87;